<template>
  <div>
    <NavBar></NavBar>
    <b-container>
      <h1>Terms and conditions</h1>
      <hr>
      <CGV></CGV>
    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import CGV from "@/components/CGV";
export default {
name: "TermsAndConditions",
  components: {CGV, Footer, NavBar},
  created() {
    window.scrollTo(0,0);
  },
}
</script>

<style scoped>

</style>